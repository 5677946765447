<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1280" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div class="box-container" style="margin: -10px -20px 0px -20px">
        <div class="box-container-inner">
          <div class="simple-query">
            <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
              <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
                <a-select v-model="queryParams.equipment_type">
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备名称" prop="equiequipment_namepmentname" style="width: 25%">
                <a-input v-model="queryParams.equipment_name"></a-input>
              </a-form-model-item>
              <div class="query-btns" style="float: right;width: 20%">
                <a-button @click="resetQueryParams">重置</a-button>
                <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
                <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
              </div>
              <div v-show="showAdvanced" class="advanced-query">
                <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                  <a-input v-model="queryParams.equipment_num"></a-input>
                </a-form-model-item>
                <a-form-model-item label="场景" prop="run_scene" style="width: 25%">
                  <a-select v-model="queryParams.run_scene">
                    <a-select-option v-for="(item, index) in equipmentSceneList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="模式" prop="pattern" style="width: 25%">
                  <a-select v-model="queryParams.pattern">
                    <a-select-option v-for="(item, index) in equipmentModeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>
            </a-form-model>
          </div>
        </div>
      </div>
      <div class="box-container" style="margin: 10px -20px 0px -20px">
        <div class="box-container-inner">
          <div class="table-header">
            <h3 class="table-title">{{pageTitle}}</h3>
            <div class="table-btns">
<!--              <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
              <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
            </div>
          </div>
          <a-table :columns="tableColumns" :data-source="tableData" row-key="operation_plan_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
            <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
            <span slot="run_scene" slot-scope="value,record">{{value?equipmentSceneList[value].dicvalue:''}}</span>
            <span slot="pattern" slot-scope="value,record">{{value?equipmentModeList[value].dicvalue:''}}</span>
            <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.operation_plan_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.operation_plan_id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.operation_plan_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import deptselect from '@/mixins/deptselect'
import {getOperationPlanListByCondition} from "A/facilityandequipment";
import {getCache, getTimeRange} from "U/index";
import pagination from "@/mixins/pagination";
export default {
  mixins: [deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'history'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '675px',
        overflowY: 'auto',
      },
      userdepidCascaderSelected: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        purpose:'',
        run_scene:'',
        pattern:'',
        time_interval1:'',
        time_interval2:'',
        time_interval3:'',
        description:'',
        status:'0',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '用途',
          dataIndex: 'purpose',
          key: 'purpose',
          ellipsis: true,
        },
        {
          title: '场景',
          dataIndex: 'run_scene',
          key: 'run_scene',
          ellipsis: true,
          scopedSlots: { customRender: 'run_scene' },
        },
        {
          title: '模式',
          dataIndex: 'pattern',
          key: 'pattern',
          ellipsis: true,
          scopedSlots: { customRender: 'pattern' },
        },
        {
          title: '时段一',
          dataIndex: 'interval1',
          key: 'interval1',
          ellipsis: true,
          scopedSlots: { customRender: 'interval1' },
        },
        {
          title: '时段二',
          dataIndex: 'interval2',
          key: 'interval2',
          ellipsis: true,
          scopedSlots: { customRender: 'interval2' },
        },
        {
          title: '时段三',
          dataIndex: 'interval3',
          key: 'interval3',
          ellipsis: true,
          scopedSlots: { customRender: 'interval3' },
        },
        {
          title: '说明',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
        },
        {
          title: '记录时间',
          dataIndex: 'create_time',
          key: 'create_time',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'create_time' },
        },
      ],
      deviceCodeList:[],
      equipmentSceneList:[],
      equipmentModeList:[],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "运行计划历史"
    },
    modalTitle() {
      return '运行计划历史';
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.getTableData();
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList;
      this.equipmentSceneList = dictionary.equipmentSceneList
      this.equipmentModeList = dictionary.equipmentModeList
    }
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        equipment_num: this.detailData?this.detailData.equipment_num:'',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      this.tableLoading = true;
      getOperationPlanListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>