<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
              <a-select v-model="queryParams.equipment_type">
                <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equiequipment_namepmentname" style="width: 25%">
              <a-input v-model="queryParams.equipment_name"></a-input>
            </a-form-model-item>
            <!--            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">-->
            <!--              <a-range-picker v-model="queryInstallDate"></a-range-picker>-->
            <!--            </a-form-model-item>-->
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="场景" prop="run_scene" style="width: 25%">
                <a-select v-model="queryParams.run_scene">
                  <a-select-option v-for="(item, index) in equipmentSceneList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="模式" prop="pattern" style="width: 25%">
                <a-select v-model="queryParams.pattern">
                  <a-select-option v-for="(item, index) in equipmentModeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="operation_plan_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="run_scene" slot-scope="value,record">{{value?equipmentSceneList[value].dicvalue:''}}</span>
          <span slot="pattern" slot-scope="value,record">{{value?equipmentModeList[value].dicvalue:''}}</span>
          <span slot="history" slot-scope="value,record"><a-button type="link" @click="showModal('history',record)">历史记录</a-button></span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.operation_plan_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.operation_plan_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.operation_plan_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <history-plan :visible.sync="historyModalVisible" :show-type="historyModalShowType" :detailData="historyModalDetailData" @get-operation-result="getTableData"></history-plan>
    <create-operation-plan :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></create-operation-plan>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {deleteOperationPlan, exportOperationPlanExcel, getOperationPlanListByCondition} from "A/facilityandequipment";
import createOperationPlan from "V/facilityAndEquipment/runManagement/createOperationPlan";
import historyPlan from "V/facilityAndEquipment/runManagement/historyPlan";
export default {
  name: "runManagement",
  mixins: [deptselect,pagination],
  components: {
    createOperationPlan,
    historyPlan,
  },
  data() {
    return {
      moment,
      userdepidCascaderSelected: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        purpose:'',
        run_scene:'',
        pattern:'',
        time_interval1:'',
        time_interval2:'',
        time_interval3:'',
        description:'',
        status:'1',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '用途',
          dataIndex: 'purpose',
          key: 'purpose',
          ellipsis: true,
        },
        {
          title: '场景',
          dataIndex: 'run_scene',
          key: 'run_scene',
          ellipsis: true,
          scopedSlots: { customRender: 'run_scene' },
        },
        {
          title: '模式',
          dataIndex: 'pattern',
          key: 'pattern',
          ellipsis: true,
          scopedSlots: { customRender: 'pattern' },
        },
        {
          title: '时段一',
          dataIndex: 'interval1',
          key: 'interval1',
          ellipsis: true,
          scopedSlots: { customRender: 'interval1' },
        },
        {
          title: '时段二',
          dataIndex: 'interval2',
          key: 'interval2',
          ellipsis: true,
          scopedSlots: { customRender: 'interval2' },
        },
        {
          title: '时段三',
          dataIndex: 'interval3',
          key: 'interval3',
          ellipsis: true,
          scopedSlots: { customRender: 'interval3' },
        },
        {
          title: '说明',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
        },
        {
          title: '记录时间',
          dataIndex: 'create_time',
          key: 'create_time',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: '历史记录',
          dataIndex: 'history',
          key: 'history',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'history' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      deviceCodeList:[],
      equipmentSceneList:[],
      equipmentModeList:[],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      historyModalVisible: false,
      historyModalShowType: '',
      historyModalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "设施设备运行时间"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"运行管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList;
      this.equipmentSceneList = dictionary.equipmentSceneList
      this.equipmentModeList = dictionary.equipmentModeList
    }
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      this.tableLoading = true;
      getOperationPlanListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let operation_plan_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'operation_plan_id', operation_plan_id);
      if(type == 'delete') {
        this.deleteConfirm(operation_plan_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportOperationPlan()
      }).catch(()=>{
      });
    },
    exportOperationPlan(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportOperationPlanExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='history'){
        this.historyModalDetailData=record
        this.historyModalShowType=type
        this.historyModalVisible=true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.operation_plan_id);
      }).catch(()=>{

      }) ;
    },
    delete(operation_plan_id) {
      this.showLoading();
      if(operation_plan_id) {
        let params = {
          operation_plan_id
        };
        deleteOperationPlan(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
  },
}
</script>
<style scoped>

</style>